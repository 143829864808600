import React from "react";
import Footer from "../Templete/Footer";
import NavBar from "../Templete/NavBar";
import style from "./style.css";

const GrievancePolicy = () => {
  return (
    <React.Fragment>
      <NavBar />

      <section class="relative table w-full py-28 lg:py-12 bg-gray-50 dark:bg-slate-800">
        <div class="container">
          <div class="grid grid-cols-1 text-center mt-10">
            <h3 class="text-3xl leading-normal font-semibold">
              Grievance Policy
            </h3>
          </div>
        </div>
      </section>
      <div class="relative">
        <div class="shape absolute right-0 sm:-bottom-px -bottom-[2px] left-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            class="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section class="relative">
        <div class="container">
          <div class="md:flex justify-center">
            <div class="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
              <p className="text-slate-400 mb-10">
                <p>
                  In accordance with Information Technology Act 2000 and rules
                  made there under, the name and contact details of the
                  Grievance Officer are provided below:
                </p>
                <p>Name: Mr. Ezazul Haque</p>
                <p>
                  Address: Formax It Solutions Pvt Ltd, Ground Floor, 16,
                  Mochpole, Near Junior School, Nabapally, Barasat, Kolkata -
                  700126, West Bengal, India
                </p>
                <p>Mobile: 7011-8161-00</p>
                <p>Email: nodal@formaxpay.com</p>
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </React.Fragment>
  );
};

export default GrievancePolicy;
