import React from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg center-nav transparent navbar-light">
        <div className="container flex-lg-row flex-nowrap align-items-center">
          <div className="navbar-brand w-100">
            <a href="/">
              <img src="/images/logo.png" height={40} alt="formaxit-logo" />
            </a>
          </div>
          <div className="navbar-collapse offcanvas offcanvas-nav offcanvas-start">
            <div className="offcanvas-header d-lg-none">
              <a href="/">
                <img src="/images/logo.png" height={40} alt="formaxit-logo" />
              </a>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              />
            </div>
            <div className="offcanvas-body ms-lg-auto d-flex flex-column h-100">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about_us">
                    About Us
                  </a>
                </li>
                <li className="nav-item dropdown dropdown-mega">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Services
                  </a>
                  <ul className="dropdown-menu mega-menu">
                    <li className="mega-menu-content">
                      <div className="row gx-0 gx-lg-3">
                        <div className="col-lg-4">
                          <h6 className="dropdown-header">IT Solutions</h6>
                          <div className="row gx-0">
                            <div className="col-lg-12">
                              <ul className="list-unstyled">
                                <li>
                                  <a className="dropdown-item" href="#">
                                    Software Development
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    Mobile App Development
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    Website Design
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    Website Development
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    Graphics Design
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item" href="#">
                                    SEO Services
                                  </a>
                                </li>
                              </ul>
                            </div>
                            {/*/column */}
                          </div>
                          {/*/.row */}
                        </div>
                        {/*/column */}
                        <div className="col-lg-4">
                          <h6 className="dropdown-header">
                            Software Solutions
                          </h6>
                          <ul className="list-unstyled">
                            <li>
                              <a className="dropdown-item" href="#">
                                Recharge Software
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                PAN Card Software
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                NEO Banking Software
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                CRM/ERP Software
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                E-commerce Portal
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                School Management Software
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                Investment Software
                              </a>
                            </li>
                          </ul>
                        </div>
                        {/*/column */}
                        <div className="col-lg-4">
                          <h6 className="dropdown-header">API Stack</h6>
                          <ul className="list-unstyled">
                            <li>
                              <a className="dropdown-item" href="#">
                                Recharge API
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                BBPS API
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                NSDL & UTI PAN Card API
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="#">
                                Insurance API
                              </a>
                            </li>
                          </ul>
                        </div>
                        {/*/column */}
                      </div>
                      {/*/.row */}
                    </li>
                    {/*/.mega-menu-content*/}
                  </ul>
                  {/*/.dropdown-menu */}
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Technology
                  </a>
                  <div className="dropdown-menu dropdown-lg">
                    <div className="dropdown-lg-content">
                      <div>
                        <ul className="list-unstyled">
                          <li>
                            <a
                              className="dropdown-item"
                              href="element-columns.html"
                            >
                              HTML
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="element-columns.html"
                            >
                              CSS
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="element-columns.html"
                            >
                              Javascript
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="element-columns.html"
                            >
                              React JS
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="element-columns.html"
                            >
                              Angular JS
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* /.column */}
                      <div>
                        <ul className="list-unstyled">
                          <li>
                            <a
                              className="dropdown-item"
                              href="element-typography.html"
                            >
                              Node JS
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="element-columns.html"
                            >
                              Java
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="element-buttons.html"
                            >
                              PHP
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="element-columns.html"
                            >
                              Python
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <ul className="list-unstyled">
                          <li>
                            <a
                              className="dropdown-item"
                              href="element-priceplan.html"
                            >
                              Android (Java/Kotlin)
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="element-counters.html"
                            >
                              Flutter
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="element-accordions.html"
                            >
                              React Native
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <ul className="list-unstyled">
                          <li>
                            <a
                              className="dropdown-item"
                              href="element-process.html"
                            >
                              MySQL
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="element-blogcard.html"
                            >
                              MongoDB
                            </a>
                          </li>
                        </ul>
                      </div>
                      {/* /.column */}
                    </div>
                    {/* /auto-column */}
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Industries
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <a className="dropdown-item" href="#">
                        Fintech
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="dropdown-item" href="#">
                        Retail
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="dropdown-item" href="#">
                        Banking & Finance
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="dropdown-item" href="#">
                        E-Commerce
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://docs.formaxpay.com"
                    target="_blank"
                  >
                    Developers API
                  </a>
                </li>
              </ul>
            </div>
            {/* /.offcanvas-body */}
          </div>
          {/* /.navbar-collapse */}
          <div className="navbar-other w-100 d-flex ms-auto">
            <ul className="navbar-nav flex-row align-items-center ms-auto">
              <li className="nav-item d-none d-md-block">
                <a
                  href="/contact_us"
                  className="btn btn-sm btn-primary rounded-pill"
                >
                  Contact
                </a>
              </li>
              <li className="nav-item d-lg-none">
                <button className="hamburger offcanvas-nav-btn">
                  <span />
                </button>
              </li>
            </ul>
            {/* /.navbar-nav */}
          </div>
          {/* /.navbar-other */}
        </div>
        {/* /.container */}
      </nav>
      {/* /.navbar */}
    </>
  );
};

export default NavBar;
