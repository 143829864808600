import React from "react";

const OurServices = () => {
  const customBox = {
    width: "5rem",
    height: "5rem",
  };
  return (
    <section className="wrapper bg-light">
      <div className="container py-14 py-md-10">
        <div className="row text-center">
          <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <h2 className="fs-15 text-uppercase text-primary mb-3">
              What We Do?
            </h2>
            <h3 className="display-4 mb-10 px-xl-10">
              The service we offer is specifically designed to meet your needs.
            </h3>
          </div>
          {/* /column */}
        </div>
        {/* /.row */}
        <div className="position-relative mb-14 mb-md-17">
          <div
            className="shape rounded-circle bg-soft-primary rellax w-16 h-16"
            data-rellax-speed={1}
            style={{ bottom: "-0.5rem", right: "-2.5rem", zIndex: 0 }}
          />
          <div
            className="shape bg-dot primary rellax w-16 h-17"
            data-rellax-speed={1}
            style={{ top: "-0.5rem", left: "-2.5rem", zIndex: 0 }}
          />
          <div className="row gx-md-5 gy-5 text-center">
            <div className="col-md-6 col-xl-4">
              <div className="card">
                <div className="card-body">
                  <div
                    className="icon btn btn-circle btn-lg btn-primary pe-none mb-3"
                    style={customBox}
                  >
                    <img
                      src="/images/software-development-icon.png"
                      alt="software-development-icon"
                      width={50}
                    />
                  </div>
                  <h4>Software Development</h4>
                  <p className="mb-2">
                    Accelerate your business with our top-tier software
                    development services tailored to meet your specific needs.
                  </p>
                  <a href="#" className="more hover link-primary">
                    Learn More
                  </a>
                </div>
                {/*/.card-body */}
              </div>
              {/*/.card */}
            </div>
            {/*/column */}
            <div className="col-md-6 col-xl-4">
              <div className="card">
                <div className="card-body">
                  <div
                    className="icon btn btn-circle btn-lg btn-primary pe-none mb-3"
                    style={customBox}
                  >
                    <img
                      src="/images/mobile-app-development-icon.png"
                      alt="mobile-app-development-icon"
                      width={60}
                    />
                  </div>
                  <h4>Mobile App Development</h4>
                  <p className="mb-2">
                    Transform your ideas into powerful mobile experiences with
                    our expert app development services.
                  </p>
                  <a href="#" className="more hover link-primary">
                    Learn More
                  </a>
                </div>
                {/*/.card-body */}
              </div>
              {/*/.card */}
            </div>
            {/*/column */}
            <div className="col-md-6 col-xl-4">
              <div className="card">
                <div className="card-body">
                  <div
                    className="icon btn btn-circle btn-lg btn-primary pe-none mb-3"
                    style={customBox}
                  >
                    <img
                      src="/images/web-design-icon.png"
                      alt="website-design-icon"
                      width={50}
                    />
                  </div>
                  <h4>Website Design</h4>
                  <p className="mb-2">
                    Empower your brand online with our comprehensive website
                    design to meet your business goals.
                  </p>
                  <a href="#" className="more hover link-primary">
                    Learn More
                  </a>
                </div>
                {/*/.card-body */}
              </div>
              {/*/.card */}
            </div>
            {/*/column */}
            <div className="col-md-6 col-xl-4">
              <div className="card">
                <div className="card-body">
                  <div
                    className="icon btn btn-circle btn-lg btn-primary pe-none mb-3"
                    style={customBox}
                  >
                    <img
                      src="/images/website-development-icon.png"
                      alt="website-development-icon"
                      width={50}
                    />
                  </div>
                  <h4>Website Development</h4>
                  <p className="mb-2">
                    We build impactful websites and web applications that
                    resonate with users and drive your business success.
                  </p>
                  <a href="#" className="more hover link-primary">
                    Learn More
                  </a>
                </div>
                {/*/.card-body */}
              </div>
              {/*/.card */}
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="card">
                <div className="card-body">
                  <div
                    className="icon btn btn-circle btn-lg btn-primary pe-none mb-3"
                    style={customBox}
                  >
                    <img
                      src="/images/graphics-design-icon.png"
                      alt="graphics-design-icon"
                      width={50}
                    />
                  </div>
                  <h4>Graphics Design</h4>
                  <p className="mb-2">
                    Elevate your brand with our comprehensive graphics design
                    services tailored to meet your unique needs.
                  </p>
                  <a href="#" className="more hover link-primary">
                    Learn More
                  </a>
                </div>
                {/*/.card-body */}
              </div>
              {/*/.card */}
            </div>
            <div className="col-md-6 col-xl-4">
              <div className="card">
                <div className="card-body">
                  <div
                    className="icon btn btn-circle btn-lg btn-primary pe-none mb-3"
                    style={customBox}
                  >
                    <img
                      src="/images/seo-services.png"
                      alt="seo-services-icon"
                      width={50}
                    />
                  </div>
                  <h4>SEO Services</h4>
                  <p className="mb-2">
                    Boost your online visibility and drive organic traffic with
                    our expert SEO services.
                  </p>
                  <a href="#" className="more hover link-primary">
                    Learn More
                  </a>
                </div>
                {/*/.card-body */}
              </div>
              {/*/.card */}
            </div>
            {/*/column */}
          </div>
          {/*/.row */}
        </div>
        {/* /.position-relative */}
        <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center mb-14 mb-md-17">
          <div className="col-lg-7">
            <figure>
              <img
                className="w-auto"
                src="./assets/img/illustrations/i11.png"
                srcSet="./assets/img/illustrations/i11@2x.png 2x"
                alt=""
              />
            </figure>
          </div>
          {/*/column */}
          <div className="col-lg-5">
            <h2 className="fs-15 text-uppercase text-primary mb-3">
              Why Choose Us?
            </h2>
            <h3 className="display-4 mb-7">
              A few reasons why our valued customers choose us.
            </h3>
            <div className="accordion accordion-wrapper" id="accordionExample">
              <div className="card plain accordion-item">
                <div className="card-header" id="headingOne">
                  <button
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    {" "}
                    Professional Design{" "}
                  </button>
                </div>
                {/*/.card-header */}
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                      Elevate your brand with our expert design services. From
                      logos to marketing materials, we craft compelling visuals
                      that resonate with your audience and reflect your brand's
                      identity
                    </p>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.accordion-collapse */}
              </div>
              {/*/.accordion-item */}
              <div className="card plain accordion-item">
                <div className="card-header" id="headingTwo">
                  <button
                    className="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {" "}
                    Latest Technology{" "}
                  </button>
                </div>
                {/*/.card-header */}
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                      Discover cutting-edge solutions at Formax It Solutions.
                      From development to design, we leverage the latest
                      technologies to drive your business forward. Partner with
                      us to innovate and stay ahead in today's digital age.
                    </p>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.accordion-collapse */}
              </div>
              {/*/.accordion-item */}
              <div className="card plain accordion-item">
                <div className="card-header" id="headingThree">
                  <button
                    className="collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Customer Support
                  </button>
                </div>
                {/*/.card-header */}
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="card-body">
                    <p>
                      Deliver exceptional customer experiences with our
                      dedicated support services. Our team ensures timely
                      assistance and personalized solutions, enhancing customer
                      satisfaction and loyalty.
                    </p>
                  </div>
                  {/*/.card-body */}
                </div>
                {/*/.accordion-collapse */}
              </div>
              {/*/.accordion-item */}
            </div>
            {/*/.accordion */}
          </div>
          {/*/column */}
        </div>
        {/*/.row */}
      </div>
      {/* /.container */}
    </section>
  );
};

export default OurServices;
