import React from "react";
import Footer from "../Templete/Footer";
import NavBar from "../Templete/NavBar";
import AboutFormaxPay from "./AboutFormaxPay";
import BusinessPartner from "./BusinessPartner";
import DownloadApp from "./DownloadApp";
import StartHero from "./StartHero";
import Testimonials from "./Testimonials";

import Header from "../Templete/Header";
import OurServices from "./OurServices";
import GetInTouch from "./GetInTouch";

const Home = () => {
  return (
    <React.Fragment>
      <div class="content-wrapper">
        <NavBar />
        <StartHero />
        <OurServices />
        <Testimonials />
        <GetInTouch />
        <Footer />
      </div>

      {/* <BusinessPartner />
      <AboutFormaxPay />
      <DownloadApp />
      */}
    </React.Fragment>
  );
};

export default Home;
